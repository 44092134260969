.container {
  flex: 1;
  width: 100%;
  display: flex;
  gap: 24px;
  flex-direction: column;

  h2 {
    color: #222;
    font-size: 24px;
    font-weight: 900;
    line-height: 32px;
  }

  p {
    color: #888;
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
  }

  .radioGroup {
    display: flex;
    gap: 12px;
    margin-bottom: 16px;

    .radioOption {
      padding: 10px 20px;
      border-radius: 12px;
      background: #fff;
      border: 2px solid #ccc;
      cursor: pointer;
      transition: 0.3s;

      input {
        display: none;
      }

      &.checked {
        border: 2px solid #670AC1;
        background: #f0e6ff;
      }
    }
  }

  .selectGroup {
    display: flex;
    gap: 10px;
    justify-content: center;

    > * {
      flex: 1;
      input {
        margin-bottom: 0;
      }
    }
  }

  select, input {
    width: 100%;
    padding: 12px;
    margin-bottom: 12px;
    border-radius: 24px;
    border: 1px solid #ccc;
  }
}
