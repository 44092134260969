.wrapper {
  @media (max-width: 480px) {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.serviceContainer {
  display: flex;
  padding: 120px 160px;
  @media (max-width: 1430px) {
    padding: 80px 40px;
  }
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;
  align-self: stretch;
  .title {
    text-align: center;
  }
  .button {
    display: flex;
    padding: var(--Sizing-M, 16px) 24px;
    justify-content: center;
    align-items: center;
    gap: var(--Sizing-M, 16px);
  }
}
.container {
  margin-top: 150px;
  padding: 80px 160px;
  @media (max-width: 1440px) {
    padding: 40px 80px;
  }
  @media (max-width: 480px) {
    padding: 40px 16px;
  }
  display: flex;
  width: 100%;
  gap: 24px;
  flex-direction: column;
  border-radius: 24px;
  background:  url("../images/banking.jpeg") lightgray 50% / cover no-repeat;

  .content {
    max-width: 600px;
    display: flex;
    gap: 24px;
    flex-direction: column;
    h1 {
      color: white;
      font-size: 48px;
      font-style: normal;
      font-weight: 700;
      line-height: 72px; /* 150% */
      @media (max-width: 768px) {
        font-size: 22px;
        font-style: normal;
        font-weight: 700;
        line-height: 30px; /* 150% */
      }
    }
    p {
      color: white;
      font-size: 18px;
      font-style: normal;
      font-weight: 300;
      line-height: 26px; /* 144.444% */
      @media (max-width: 768px) {
        font-size: 14px;
        font-style: normal;
        line-height: 20px; /* 142.857% */
      }
    }
    .buttons {
      display: flex;
      flex-direction: row;
      gap: 16px;
      margin-top: 40px;
    }
  }
}
.banner {
  color: #000;
  text-align: center;
  font-size: 32px;
  font-style: normal;
  font-weight: 300;
  line-height: 48px; /* 150% */
  @media (max-width: 768px) {
    font-size: 22px;
    line-height: 30px; /* 150% */
  }
}
.bannerButtons {
    display: flex;
    justify-content: center;
    gap: 16px;
    margin-top: 40px;
}
.howImage {
  width: 100%;
  height: auto;
  max-width: 600px;
}
.textTitle {
  color: #000;
  text-align: center;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 48px; /* 150% */
  @media (max-width: 768px) {
    font-size: 22px;
    line-height: 30px; /* 150% */
  }
}
.text {
  color: #222;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: 30px; /* 150% */
}